import { LIBRARY_THEME } from "~/lib/theme";

export const DEFAULT_SEO = {
  title: LIBRARY_THEME.partnerName,
  description: LIBRARY_THEME.partnerOnlineName,
  openGraph: {
    type: "website",
    url: process.env.NEXT_PUBLIC_PROD_URL,
    image:
      "https://images.prismic.io/kup-georgetown/5b322014-1f89-451d-85ed-1956e1f229ce_georgetown-social.png",
    imageWidth: "1162",
    imageHeight: "440",
  },
  twitter: {
    cardType: "summary_large_image",
  },
};
