// @ts-nocheck
// Typescript is upset about key differences between
// slicemachine and non slice machine projects
// - jerry July 13, 2023

import * as prismic from "@prismicio/client";
import { enableAutoPreviews } from "@prismicio/next";
import { format, sub } from "date-fns";

import { getProgramCohorts } from "./hall-of-records";

import type { Cohort } from "./types/cohort";
import type {
  FilledContentRelationshipField,
  LinkField,
  PrismicDocument,
} from "@prismicio/client";
import type { CreateClientConfig } from "@prismicio/next";
import type { GetStaticProps, GetStaticPropsContext } from "next";

type _Cohort = {
  applicationDeadline: string;
  enrollmentDeadline: string;
};

type _Slice = {
  slice_type: string;
  primary: {
    link: {
      id: string;
    };
  };
};

export type NavProps = PrismicDocument<Record<string, any>, string, string>;

const formatDate = function (date, pattern, subtract = {}) {
  /*
    NOTE: subtract is here as a hack to fix the display of the
    registration deadline. Timezone stuff and what not.
    Revisit in Season 3
  */
  return format(sub(new Date(date), subtract), pattern || "MMMM d");
};

const _getCohorts = async function (deadlineKey: string, programIDs: string) {
  const response = await getProgramCohorts(programIDs);

  if (response.ok) {
    const _data = await response.json();
    return [].concat.apply(
      [],
      _data.map((catalogueItem: { cohorts: _Cohort[] }) => {
        return catalogueItem.cohorts.filter((c) => {
          if (deadlineKey === "registration" || deadlineKey === "application") {
            return new Date(c.applicationDeadline) > new Date();
          } else {
            return new Date(c.enrollmentDeadline) > new Date();
          }
        });
      }),
    );
  }

  return [];
};

export function linkResolver(doc: FilledContentRelationshipField) {
  switch (doc.type) {
    case "page":
      return `/${doc.uid}`;
    default:
      return null;
  }
}

/*
  hrefResolver for Prismic links
  Depending on the type (Web, Document or Media), the data will be different
*/
export const hrefResolver = (data: LinkField) => {
  switch (data.link_type) {
    case "Media":
    case "Web":
      return data.url;
    case "Document":
      return "/" + data.uid;
    default:
      return "#";
  }
};

/**
 * getRepositoryName
 * @param {string} endpoint
 * @returns {string}
 */
export const getRepositoryName = function (endpoint: string) {
  return prismic.getRepositoryName(endpoint);
};

/**
 * createClient
 * @param {string} endpoint
 * @param {object} config
 * @returns {prismic.Client}
 */
export function createClient(
  endpoint: string,
  config: CreateClientConfig = {},
) {
  const client = prismic.createClient(endpoint, {
    ...(config as prismic.ClientConfig),
  });

  const previewConfig = {
    client,
    previewData: config.previewData,
    req: config.req,
  };

  enableAutoPreviews(previewConfig);

  return client;
}

/**
 * getPaths
 * @returns {object}
 */
export async function getPaths() {
  const client = createClient(
    process.env.NEXT_PUBLIC_PRISMIC_API_URL as string,
  );
  const pages = await client.getAllByType("page");
  const resolver = linkResolver as prismic.LinkResolverFunction;

  return {
    fallback: "blocking",
    paths: pages.map((p) => {
      const _p = p as PrismicDocument;
      return prismic.asLink(_p, { linkResolver: resolver });
    }),
  };
}

export async function getPropsForSingle(
  context: { preview: boolean; previewData: string },
  type: string,
  id: string,
) {
  const { preview, previewData } = context;

  const client = createClient(
    process.env.NEXT_PUBLIC_PRISMIC_API_URL as string,
    {
      previewData,
    },
  );

  // Page
  const page = await client.getByUID(type, id);

  // Navigation
  //const navUID = page.data.navigation?.uid ?? "navigation-default";
  const navUID = "navigation-default";
  const navigation = await client.getByUID("navigation", navUID);

  return {
    props: {
      cohortData: [],
      navigation,
      page,
      preview: preview ?? null,
      reusableContentData: [],
    },
  };
}

export const getPropsForAccountPage = (async () => {
  const client = createClient(
    process.env.NEXT_PUBLIC_PRISMIC_API_URL as string,
  );
  const navigation = await client.getByUID("navigation", "navigation-default");

  return {
    props: {
      navigation,
    },
  };
}) satisfies GetStaticProps<{
  navigation: NavProps;
}>;

/**
 * getProps
 * @param {object} context
 * @param {string} [uid]
 * @param {object} [options]
 * @returns {object}
 */
export async function getProps(context: GetStaticPropsContext, uid?: string) {
  const { params, preview, previewData } = context;
  const UID = uid || params.uid;

  const client = createClient(
    process.env.NEXT_PUBLIC_PRISMIC_API_URL as string,
    {
      previewData,
    },
  );

  // Page
  const page = await client.getByUID("page", UID);

  // Navigation
  // const navUID = page.data.navigation?.uid ?? "navigation-default";
  const navigation = await client.getByUID("navigation", "navigation-default");

  // Slices
  // Older Prismic sites use different keys (probably slice machine vs classic web editor)
  // when all sites are on slice machine `page_content` can be removed
  const _slices = page.data.slices ?? page.data.page_content;

  // Cohorts
  const cohortSlice = _slices.find((s: _Slice) => {
    // "cohort_listing" is from older sites
    return s.slice_type === "cohort_list" || s.slice_type === "cohort_listing";
  });

  // hor_program_id from old sites
  const _ID_KEYS =
    cohortSlice && cohortSlice.primary.program_ids
      ? "program_ids"
      : "hor_program_id";

  const _cohortData = cohortSlice
    ? await _getCohorts(
        cohortSlice.primary.deadline,
        (cohortSlice.primary[_ID_KEYS] ?? "")
          .replace(/ /g, "")
          .split(",")
          .toString(),
      )
    : [];

  const cohortData: Cohort[] = _cohortData
    .map((c) => {
      return {
        _id: c._id,
        catalogueItemName: c.catalogueItem.name,
        catalogueItemType: c.catalogueItem.type,
        closed: c.redemptionCount >= c.maxCapacity,
        cohortLength: c.cohortLength,
        applicationDeadline: c.applicationDeadline,
        enrollmentDeadline: c.enrollmentDeadline,
        formattedApplicationDeadline: c.formattedApplicationDeadline,
        formattedEnrollmentDeadline: c.formattedEnrollmentDeadline,
        formattedDateRange: `${formatDate(c.startDate)} - ${formatDate(
          c.endDate,
        )}`,
        formattedRetailPrice: c.formattedRetailPrice,
        formattedStartDate: formatDate(c.startDate, "MMMM d, yyyy"),
        startDate: c.startDate,
      };
    })
    .sort((a, b) => {
      if (a.startDate > b.startDate) {
        return 1;
      } else if (a.startDate < b.startDate) {
        return -1;
      } else {
        return 0;
      }
    });

  // Reusable Content
  const reusableContentData = await Promise.all(
    _slices
      .filter((s: _Slice) => {
        return s.slice_type === "reusable_content";
      })
      .map((slice: _Slice) => {
        const ID = slice.primary.link.id as string;
        return client.getByID(ID);
      }),
  );

  return {
    props: {
      cohortData,
      navigation,
      page,
      preview: preview ?? null,
      previewData: previewData ?? null,
      reusableContentData,
      uid: UID,
    },
  };
}
