import type { TLibraryTheme } from "@up/data";

export const LIBRARY_THEME: TLibraryTheme = {
  analyticsPartnerName: "georgetown",
  buttonPrimaryClass: "app-button-primary",
  buttonSecondaryClass: "app-button-secondary",
  buttonTertiaryClass: "",
  heading1Class: "focus:outline-none",
  heading2Class: "text-3xl focus:outline-none",
  heading3Class: "text-xl font-sans font-bold",
  lmsURL: "https://app.georgetown.precollegeprograms.org",
  partnerID: process.env.NEXT_PUBLIC_HALL_OF_RECORDS_PARTNER_ID as string,
  partnerPrivacyPolicy: "https://www.georgetown.edu/privacy-policy",
  partnerName: "Georgetown University",
  partnerOnlineName: "Georgetown Pre-College Online Program",
  primaryColor: "#041e42",
  supportEmail: "support@precollegeonline.georgetown.edu",
};
